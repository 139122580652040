import {
  WidgetTitleContainer,
  WidgetTitle,
  WidgetDescription,
} from "../Resume/Resume";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SortableKnob } from "react-easy-sort";
import {
  analisisValues,
  message,
  colorPalette,
  formatDateToYYYYMMDD,
} from "../../../utils/formats";
import {
  ChartContainer,
  WidgetHeaderContainer,
  WidgetHeaderSubContainer,
} from "./Analysis";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { useState } from "react";
import { useDataProvider } from "../../../context/provider";
import { styled } from "styled-components";
import { DataMessage } from "../EfficientPurchasing/EfficientPurchasing";
import { NumberUtils } from "../../../utils";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
} from "@extendeal/vendors-design-system";

interface DrugData {
  date: string;
  drug_manufacturer_id: number;
  drug_manufacturer_name: string;
  total: number;
  total_sum: number;
}

interface ManufacturerData {
  name: string;
  data: number[];
}
const getUniqueDates = (data: DrugData[]) => {
  return Array.from(new Set(data.map((item) => item.date))).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );
};
const getManufacturerDataByDate = (data: DrugData[]): ManufacturerData[] => {
  const uniqueDates = Array.from(new Set(data?.map((item) => item.date))).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  const manufacturers = data.reduce(
    (acc: Record<string, Record<string, number>>, item) => {
      if (!acc[item.drug_manufacturer_name]) {
        acc[item.drug_manufacturer_name] = {};
      }
      acc[item.drug_manufacturer_name][item.date] = item.total;
      return acc;
    },
    {}
  );

  const result: ManufacturerData[] = Object.entries(manufacturers).map(
    ([name, records]) => {
      const data = uniqueDates.map((date) => records[date] || 0);
      return { name, data };
    }
  );

  return result;
};

export default function DailySpendingByDrugstore() {
  const { state, isLoading } = useDataProvider();
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();

  const [filter, setFilter] = useState("15");
  const uniqueDates = state.moneyPerDm
    ? getUniqueDates(state.moneyPerDm)
    : null;

  return (
    <>
      <WidgetHeaderContainer>
        <WidgetHeaderSubContainer>
          {" "}
          <WidgetTitleContainer>
            {isReordering && (
              <SortableKnob>
                <Box>
                  <Icon as="drag" color="neutral-600" />
                </Box>
              </SortableKnob>
            )}
            <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
              <Icon as="storeFront" type="outline" color="neutral-800" />
            </Box>
            <WidgetTitle>{analisisValues.money_per_dm.title}</WidgetTitle>
          </WidgetTitleContainer>
          <WidgetDescription>
            {analisisValues.money_per_dm.description}
          </WidgetDescription>
        </WidgetHeaderSubContainer>
        <div style={{ display: "flex" }}>
          <WidgetHeaderSubContainer>
            {isReordering && (
              <Box>
                {" "}
                {visibility.money_per_dm ? (
                  <Button
                    backgroundColor={"neutral-white"}
                    p={"1"}
                    onClick={() => toggleWidgetVisibility("money_per_dm")}
                  >
                    <Icon as="eye" type="outline" />
                  </Button>
                ) : (
                  <Button
                    backgroundColor={"neutral-white"}
                    p={"1"}
                    onClick={() => toggleWidgetVisibility("money_per_dm")}
                  >
                    <Icon as="eyeOff" />
                  </Button>
                )}
              </Box>
            )}
          </WidgetHeaderSubContainer>
        </div>
      </WidgetHeaderContainer>
      {isLoading ? (
        <Stack alignItems={"center"} justifyContent="center">
          <Skeleton w={"100%"} h="400px" />
        </Stack>
      ) : (
        <ChartContainer>
          {state.moneyPerDm?.length ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: ".",
                  style: {
                    color: "white",
                  },
                },
                tooltip: {
                  pointFormat: "<b>$ {point.y}</b>",
                },
                xAxis: {
                  categories: uniqueDates,
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: "Monto gastado",
                    style: {
                      color: "#333333",
                      fontSize: 14,
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: 12,
                    },
                  },
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                  gridLineDashStyle: "Dot",
                },
                colors: colorPalette,
                exporting: {
                  enabled: true,
                  filename: `${formatDateToYYYYMMDD(new Date())}_${
                    analisisValues.money_per_dm.title
                  }`,
                  buttons: {
                    contextButton: {
                      menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "downloadXLS",
                      ],
                    },
                  },
                },
                plotOptions: {
                  column: {
                    stacking: "normal",
                    dataLabels: {
                      enabled: true,
                      inside: true,
                      color: "#FFFFFF",
                      style: {
                        textOutline: "none",
                      },
                      formatter: function () {
                        if (this.y !== 0) {
                          return NumberUtils.formatWithSuffix(this.y);
                        } else {
                          return null;
                        }
                      },
                    },
                  },
                },
                credits: {
                  enabled: false,
                },
                series: state.medicinalTop20
                  ? getManufacturerDataByDate(state.moneyPerDm)
                  : null,
              }}
            />
          ) : (
            <DataMessage>{message.no_data}</DataMessage>
          )}
        </ChartContainer>
      )}
    </>
  );
}
const FilterButton = styled.button<{ selected: boolean }>`
  margin: 0 5px;
  height: 35px;
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-700, var(--700, #646464));
  box-shadow: ${(props) =>
    props.selected ? "0 0 0 3px var(--Primary-600, #0171E6)" : "0 0 0 0px "};
  border: ${(props) =>
    props.selected
      ? "0px solid var(--Primary-600, #0171E6)"
      : "1px solid var(--700, #646464)"};
  border-radius: 20px;
  outline: none;
  background-color: white;
  &:hover {
    background: var(--Primary-10, #ebf5ff);
    color: var(--Primary-600, #0171e6);
    border: ${(props) =>
      props.selected
        ? "0px solid var(--Primary-600, #0171E6)"
        : "1px solid transparent"};
  }
`;
