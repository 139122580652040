import { Schema } from 'rsuite';

const { StringType, NumberType, ObjectType } = Schema.Types;

export const formSchema = Schema.Model({
    username: StringType().isRequired('El usuario es requerido'),
    password: StringType().isRequired('La contraseña es requerida'),
    client_identifier: NumberType().pattern(/^\d+$/, 'El dato ingresado debe ser numerico'),
    function: StringType(), // TODO: @EXE se cambio tipo de dato porque no dejaba avanzar
});

// esquema de validación exclusivo para droguerías con client_id solamente
export const validationSchemeOnlyWithClientId = Schema.Model({
    client_identifier: NumberType()
        .isRequired('El número de cliente es requerido')
        .pattern(/^\d+$/, 'El dato ingresado debe ser numerico'),
    function: StringType(), // TODO: @EXE se cambio tipo de dato porque no dejaba avanzar
});

export const validationSchemaWithClientIdAndCatalog = Schema.Model({
    client_identifier: NumberType()
        .isRequired('El número de cliente es requerido')
        .pattern(/^\d{1,13}$/, 'El dato ingresado debe ser numerico y hasta 13 dígitos'),
    catalog_id: NumberType().isRequired('El catálogo es requerido'),
});

export const validationSchemaWithCommonAndClientId = Schema.Model({
    username: StringType().isRequired('El usuario es requerido'),
    password: StringType().isRequired('La contraseña es requerida'),
    client_identifier: StringType().isRequired('El depósito es requerido'),
});

export const validationSchemaCoopidrogas = Schema.Model({
    username: StringType().isRequired('El usuario es requerido'),
    password: StringType().isRequired('La contraseña es requerida'),
    client_identifier: StringType().isRequired('El tipo de cliente es requerido'),
});

export const validationSchemaNadro = Schema.Model({
    username: StringType().isRequired('El usuario es requerido'),
    password: StringType().isRequired('La contraseña es requerida'),
    client_identifier: StringType().isRequired('El tipo de cliente es requerido'),
});

export const validationSchemaNadroV2 = Schema.Model({
    username: StringType().isRequired('Campo requerido'),
    password: StringType().isRequired('Campo requerido'),
});

export const validationSchemaFarmater = Schema.Model({
    username: StringType().isRequired('El usuario es requerido'),
    password: StringType().isRequired('La contraseña es requerida'),
    payment_method: ObjectType().isRequired('Campo obligatorio'),
});

export const validationSchemaPaymentMethod = Schema.Model({
    username: StringType().isRequired('Campo requerido'),
    password: StringType().isRequired('Campo requerido'),
    payment_method_id: NumberType().isRequired('Campo requerido'),
});

export const validationSchemaFarmaMX = Schema.Model({
    username: StringType().isRequired('El usuario es requerido'),
    password: StringType().isRequired('La contraseña es requerida'),
    client_identifier: StringType().isRequired('El depósito es requerido'),
});

export const validationSchemeAvailableBranchesToDispatchAddress = Schema.Model({
    username: StringType().isRequired('Campo requerido'),
    password: StringType().isRequired('Campo requerido'),
    client_identifier: StringType().isRequired('Campo requerido'),
});

export const validationSchemeDispatchAddress = Schema.Model({
    username: StringType().isRequired('Campo requerido'),
    password: StringType().isRequired('Campo requerido'),
    dispatch_address: ObjectType().isRequired('Campo requerido'),
});

export const initialValue = {
    username: '',
    password: '',
    function: '',
    client_identifier: '',
    dispatch_address: {},
    payment_method_id: '',
    available_branches: '',
    catalog_id: '',
};

export const credentialData = {
    credentialId: null,
    nameType: '',
    drugName: '',
};
