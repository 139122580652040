import { ReactNode, useContext, useReducer, useState } from "react";
import useAnalitycsApi from "../hooks/api/useAnalyticsApi";
import { AnalyticsApi } from "../models/analitycsApi";
import { createContext } from "react";
import { areAnalyticsEmpty } from "./lib";
export interface State {
  resume: AnalyticsApi.GetAnalytics.SummaryResponse;
  drugDistribution: AnalyticsApi.GetAnalytics.PurchasesByDistributorResponse;
  moneyPerDm: AnalyticsApi.GetAnalytics.DailySpendingByDistributorResponse;
  medicinalTop20: AnalyticsApi.GetAnalytics.TopPurchasedProductsResponse;
  notMedicinalTop20: AnalyticsApi.GetAnalytics.TopPurchasedProductsResponse;
  productsDistribution: AnalyticsApi.GetAnalytics.PurchaseDistributionProductsResponse;
  purchaseByPos: AnalyticsApi.GetAnalytics.PurchasesByPosResponse;
  purchasePerDm: AnalyticsApi.GetAnalytics.DistributorPurchaseAndOrdersResponse;
  reorder: boolean;
}
interface ProviderValues {
  getAllData: (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => void;
  setReorder: (value: boolean) => void;
  getPointsOfSale: () => Promise<AnalyticsApi.GetPosId.Response | undefined>;
  state: State;
  pointsOfSale: AnalyticsApi.GetPosId.Response | undefined;
  isLoading: boolean;
  isEmpty: boolean;
}
const ACTIONS = {
  GET_ALL_DATA: "GET_ALL_DATA",
  GET_RESUME: "GET_RESUME",
  SET_FILTER_ORDER: "SET_FILTER_ORDER",
  SET_WIDGET_ORDER: "SET_WIDGET_ORDER",
  SET_REORDER: "SET_REORDER",
};
const summaryValues = {
  total: 0,
  variation: 0,
};
const initialState: State = {
  resume: {
    total_purchased: summaryValues,
    buyed_vs_efficient: summaryValues,
    medicinal_total: summaryValues,
    non_medicinal_total: summaryValues,
    orders: summaryValues,
    prices_variation: summaryValues,
  },
  drugDistribution: [],
  productsDistribution: [],
  moneyPerDm: [],
  medicinalTop20: [],
  notMedicinalTop20: [],
  purchaseByPos: [],
  purchasePerDm: [],
  reorder: false,
};

const DataContext = createContext<ProviderValues | undefined>(undefined);

const reducer = (
  state: State,
  action: { type: string; payload?: any }
): State => {
  switch (action.type) {
    case ACTIONS.GET_RESUME:
      return {
        ...state,
        resume: action.payload,
      };
    case ACTIONS.GET_ALL_DATA:
      return {
        ...state,
        resume: action.payload.resume,
        drugDistribution: action.payload.drugDistribution,
        productsDistribution: action.payload.productsDistribution,
        moneyPerDm: action.payload.moneyPerDm,
        medicinalTop20: action.payload.medicinalTop20,
        notMedicinalTop20: action.payload.notMedicinalTop20,
        purchaseByPos: action.payload.purchaseByPos,
        purchasePerDm: action.payload.purchasePerDm,
      };
    case ACTIONS.SET_REORDER:
      return {
        ...state,
        reorder: action.payload,
      };

    default:
      return state;
  }
};
interface DataProviderProps {
  children: ReactNode;
}
export const DataProvider = ({
  children,
  ...otherProps
}: DataProviderProps) => {
  const { getAnalitycs, getPosId } = useAnalitycsApi();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pointsOfSale, setPointsOfSale] =
    useState<AnalyticsApi.GetPosId.Response>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getPointsOfSale = async (): Promise<
    AnalyticsApi.GetPosId.Response | undefined
  > => {
    const response = await getPosId();
    if (!response) return;

    setPointsOfSale(response);
    return response;
  };
  const getResume = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "resume",
      pos_id,
      date_i,
      date_f,
      timezone,
    });

    return result;
  };

  const getDistrPurchPerDm = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "distrib_purchase_per_dm",
      pos_id,
      date_i,
      date_f,
      timezone,
    });

    return result;
  };
  const getDistrPurchMeds = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "distrib_purchase_meds_and_not_meds",
      pos_id,
      date_i,
      date_f,
      timezone,
    });

    return result;
  };
  const getMoneyPerDm = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "money_per_dm",
      pos_id,
      date_i,
      date_f,
      timezone,
    });
    return result;
  };
  const getMedicinalTop20 = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "top_n_meds",
      pos_id,
      date_i,
      date_f,
      timezone,
    });
    return result;
  };

  const getNotMedicinalTop20 = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "top_n_no_meds",
      pos_id,
      date_i,
      date_f,
      timezone,
    });
    return result;
  };
  const getPurchaseByPos = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "real_and_orders_purchase_per_pos",
      pos_id,
      date_i,
      date_f,
      timezone,
    });
    return result;
  };
  const getPurchasePerDm = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ) => {
    const result = await getAnalitycs({
      ep_name: "real_and_orders_purchase_per_dm",
      pos_id,
      date_i,
      date_f,
      timezone,
    });
    return result;
  };

  const getAllData = async (
    pos_id: number[],
    date_i: string,
    date_f: string,
    timezone?: string
  ): Promise<void> => {
    setIsLoading(true);
    const [
      resume,
      drugDistribution,
      productsDistribution,
      moneyPerDm,
      medicinalTop20,
      notMedicinalTop20,
      purchaseByPos,
      purchasePerDm,
    ] = await Promise.all([
      getResume(pos_id, date_i, date_f, timezone),
      getDistrPurchPerDm(pos_id, date_i, date_f, timezone),
      getDistrPurchMeds(pos_id, date_i, date_f, timezone),
      getMoneyPerDm(pos_id, date_i, date_f, timezone),
      getMedicinalTop20(pos_id, date_i, date_f, timezone),
      getNotMedicinalTop20(pos_id, date_i, date_f, timezone),
      getPurchaseByPos(pos_id, date_i, date_f, timezone),
      getPurchasePerDm(pos_id, date_i, date_f, timezone),
    ]);

    dispatch({
      type: ACTIONS.GET_ALL_DATA,
      payload: {
        resume,
        drugDistribution,
        productsDistribution,
        moneyPerDm,
        medicinalTop20,
        notMedicinalTop20,
        purchaseByPos,
        purchasePerDm,
      },
    });
    setIsLoading(false);
  };

  const setReorder = (value: boolean) => {
    dispatch({ type: ACTIONS.SET_REORDER, payload: value });
  };

  const value: ProviderValues = {
    getAllData,
    setReorder,
    getPointsOfSale,
    state,
    pointsOfSale,
    isLoading,
    isEmpty: !isLoading && areAnalyticsEmpty(state),
  };

  return (
    <DataContext.Provider value={value} {...otherProps}>
      {children}
    </DataContext.Provider>
  );
};
export const useDataProvider = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataProvider must be used within a DataProvider");
  }
  return context;
};
