import {
  Box,
  Card,
  Image,
  Stack,
  Text,
} from "@extendeal/vendors-design-system";
import { navbarHeight } from "../../App";
import searchError from "../../assets/searchError.svg";

const NoAnalyticsFound = () => {
  return (
    <Card
      h={`calc(100vh - ${navbarHeight})`}
      borderRadius="lg"
      boxShadow="sm"
      w="100%"
      backgroundColor={"primary-10"}
    >
      <Stack justifyContent="center" alignItems="center" h="100%">
        <Box w="21.5rem">
          <Stack flexDirection="column" gap="8" alignItems="center">
            <Image src={searchError} w="8.5rem" />
            <Stack flexDirection="column" gap="4">
              <Text textAlign="center" fontSize="lg" fontWeight="semibold">
                No encontramos datos con los filtros aplicados.
              </Text>
              <Text textAlign="center">
                Podés ampliar la fecha de búsqueda para obtener más resultados.
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

export default NoAnalyticsFound;
