import { apiPharmaInstance } from 'utils/axios';

export const selectAllDrugManufacturer = async ({ orderId, drugManufacturerId }) => {
    let url = `/api/orders/${orderId}/select-all/${drugManufacturerId}`;
    try {
        return apiPharmaInstance
            .post(url)
            .then((res) => res.data.data)
            .catch((e) => console.error(e));
    } catch (ex) {
        console.error(ex.message);
    }
};

export const removeSelectAllDrugManufacturer = async ({ orderId }) => {
    let url = `/api/orders/${orderId}/deselect-all`;
    try {
        return apiPharmaInstance
            .post(url)
            .then((res) => res.data.data)
            .catch((e) => console.error(e));
    } catch (ex) {
        console.error(ex.message);
    }
};
