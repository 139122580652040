import { ReorderProvider } from "./context/ReorderContext/provider";
import { useEffect } from "react";
import { useDataProvider } from "./context/provider";
import Main from "./components/templates/Main";
import Searchbar from "./components/templates/Searchbar";
import { x } from "@xstyled/styled-components";
import Exporting from "highcharts/modules/exporting";
import offlineExportingModule from "highcharts/modules/offline-exporting";
import Highcharts from "highcharts";
import exportDataModule from "highcharts/modules/export-data";

if (typeof Exporting === "function") {
  Exporting(Highcharts);
  offlineExportingModule(Highcharts);
  exportDataModule(Highcharts);
}
Highcharts.setOptions({
  lang: {
    viewFullscreen: "Ver en pantalla completa",
    printChart: "Imprimir gráfico",
    downloadPNG: "Descargar imagen PNG",
    downloadJPEG: "Descargar imagen JPEG",
    downloadPDF: "Descargar archivo PDF",
    downloadSVG: "Descargar imagen SVG",
    downloadCSV: "Descargar CSV",
    downloadXLS: "Descargar archivo XLS",
    viewData: "Ver tabla de datos",
  },
});
export const navbarHeight = "76px";
function App() {
  const { getPointsOfSale, pointsOfSale, isEmpty } = useDataProvider();
  useEffect(() => {
    getPointsOfSale();
  }, []);

  return (
    <ReorderProvider>
      <x.div
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        minH={`calc(100vh - ${navbarHeight})`}
        backgroundColor={"#f0f5ff"}
      >
        <x.div w={"full"} maxW="1280px"></x.div>
        {pointsOfSale ? (
          <>
            <Searchbar />
            <Main />
          </>
        ) : null}
      </x.div>
    </ReorderProvider>
  );
}

export default App;
