import { styled } from "styled-components";
import { transparentize } from "polished";
import { CustomProvider, Button as RSuiteButton } from "rsuite";
import Checkbox from "rsuite/Checkbox";
import "rsuite/Checkbox/styles/index.css";
import CheckPicker from "rsuite/CheckPicker";
import "rsuite/CheckPicker/styles/index.css";
import { DateRange, DateRangePickerProps } from "rsuite/esm/DateRangePicker";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { createGlobalStyle } from "styled-components";
import { MdOutlineCalendarToday } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { esES } from "rsuite/locales";
import { useDataProvider } from "../../context/provider";
import { useReorderProvider } from "../../context/ReorderContext/provider";
import {
  Box,
  Button,
  DateRangePicker,
  Icon,
} from "@extendeal/vendors-design-system";

import startOfDay from "date-fns/startOfDay";

const predefinedRanges: DateRangePickerProps["ranges"] = [
  {
    label: "Ultimos 7 días",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Semana actual",
    value: [startOfWeek(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Semana pasada",

    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    placement: "left",
  },
  {
    label: "Mes actual",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Mes pasado",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
];

const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

export default function Searchbar() {
  const { getAllData, setReorder, state, pointsOfSale } = useDataProvider();
  const { isReordering, saveChanges, setIsReordering, cancelChanges } =
    useReorderProvider();

  const picker = React.useRef();
  const today = startOfDay(new Date());

  const oneYearAgo = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );
  const oneWeekAgo = startOfDay(subDays(today, 6));
  const [date, setDate] = useState<DateRange>([oneWeekAgo, today]);
  const [posId, setPosId] = useState<number[] | undefined>();
  const data = pointsOfSale?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const allPosIds = pointsOfSale?.map((item) => item.id);
  const [selectValue, setSelectValue] = useState([]);

  const handleCheckAll = (value, checked: boolean) => {
    setSelectValue(checked ? allPosIds : []);
  };
  const handleClick = (value) => {
    if (value.length > 0) {
      setPosId(value);
    } else {
      setPosId(allPosIds);
    }
  };
  const handleChange = (value) => {
    setSelectValue(value);
  };

  useEffect(() => {
    setPosId(allPosIds);
  }, [pointsOfSale]);

  const fetchAnalyticsData = (value: number[]) => {
    const response = getAllData(
      value,
      date[0]?.toISOString().slice(0, 10),
      date[1]?.toISOString().slice(0, 10),
      Object.values(pointsOfSale)[0].timezone
    );

    return response;
  };

  useEffect(() => {
    if (!date || !pointsOfSale?.length || !posId) return;
    fetchAnalyticsData(posId);
  }, [date, pointsOfSale, posId]);

  const toggleReorderView = () => {
    setReorder(!state.reorder);
    setIsReordering(!isReordering);
  };
  const handleDateChange = (value: DateRange | null) => {
    if (!value) {
      return setDate([oneWeekAgo, today]);
    }
    setDate(value);
  };

  return (
    <Container>
      {isReordering ? (
        <CancelAndReorderButtonContainer>
          <CancelButton onClick={cancelChanges}>Cancelar</CancelButton>
          <ReorderButton onClick={saveChanges}>Guardar cambios</ReorderButton>
        </CancelAndReorderButtonContainer>
      ) : (
        <>
          <Box>
            <CheckPickerGlobalStyles />

            <CustomProvider locale={esES}>
              <DateRangePicker
                className="daterange-design-system"
                locale={esES.DateRangePicker}
                ranges={predefinedRanges}
                value={date}
                caretAs={MdOutlineCalendarToday}
                format="dd/MM/yyyy"
                placeholder="Fecha"
                character="-"
                placement={
                  pointsOfSale?.length <= 1 ? "bottomEnd" : "bottomStart"
                }
                editable={false}
                shouldDisableDate={(date) => {
                  return (
                    date > new Date() || date.getTime() < oneYearAgo.getTime()
                  );
                }}
                defaultOpen={false}
                onShortcutClick={(shortcut, event) => {
                  handleDateChange(shortcut.value as DateRange);
                }}
                onChange={(value) => {
                  handleDateChange(value);
                }}
              />
            </CustomProvider>
          </Box>
          <Box>
            {pointsOfSale?.length > 1 && (
              <>
                <CheckPicker
                  className="custom-design-system"
                  locale={{
                    searchPlaceholder: "Buscar",
                  }}
                  onExit={() => handleClick(selectValue)}
                  data={data}
                  caretAs={null}
                  placeholder="Todos los puntos de venta"
                  ref={picker}
                  menuAutoWidth={false}
                  style={{ width: "485px" }}
                  menuStyle={{ width: "485px", position: "absolute" }}
                  value={selectValue}
                  onChange={handleChange}
                  onClean={() => {
                    setSelectValue([]);
                    handleClick(allPosIds);
                  }}
                  renderExtraFooter={() => (
                    <div style={footerStyles}>
                      <Checkbox
                        checked={selectValue.length === allPosIds?.length}
                        onChange={handleCheckAll}
                      >
                        Seleccionar todo
                      </Checkbox>

                      <RSuiteButton
                        style={{
                          float: "right",
                          marginRight: 10,
                          marginTop: 2,
                        }}
                        appearance="primary"
                        size="sm"
                        onClick={() => {
                          handleClick(selectValue);
                          //@ts-expect-error
                          picker.current.close();
                        }}
                      >
                        Aplicar
                      </RSuiteButton>
                    </div>
                  )}
                />
              </>
            )}
          </Box>
          <Box>
            <Button
              backgroundColor={"primary-600"}
              color={"neutral-white"}
              py={"2"}
              onClick={toggleReorderView}
            >
              <Icon as="drag" color="neutral-white" />
              Reordenar reportes
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}

const CancelButton = styled.div`
  display: flex;
  height: 36px;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #0171e6);
  color: var(--Primary-600, #0171e6);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin-right: 10px;
`;
const ReorderButton = styled.div`
  display: flex;
  height: 36px;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Primary-600, #0171e6);
  color: var(--Neutral-White, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  @media (max-width: 1331px) {
    padding: 3px 10px;
  }
`;
const CancelAndReorderButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  /* height: 70px; */
  position: relative;
  background-color: white;
  padding: 20px 60px;
  box-shadow: 0 0 5px ${transparentize(0.8, "#40435B")};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const CheckPickerGlobalStyles = createGlobalStyle({
  ".custom-design-system": {
    ".rs-picker-value-item": {
      paddingLeft: "1px",
    },
    ".rs-picker-default": {
      ".rs-picker-toggle": {
        height: "36px",
      },
    },
  },
  ".daterange-design-system": {
    ".rs-input-group-addon": {
      ".rs-btn-close": {
        padding: "10px !important",
      },
    },
  },
});
