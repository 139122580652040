import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { Header, Line, Title } from "../Resume/Resume";
import { useCallback, useState } from "react";
import { styled } from "styled-components";
import { arrayMoveImmutable } from "array-move";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import DrugstoreOrdersAndTotalAmount from "./DrugstoreOrdersAndTotalAmount";
import POSOrdersAndTotalAmount from "./POSOrdersAndTotalAmount";
import { Box, Button, Icon } from "@extendeal/vendors-design-system";

const EfficientPurchasing = () => {
  const {
    items,
    updateItems,
    isReordering,
    visibility,
    toggleWidgetVisibility,
  } = useReorderProvider();
  const [realVsEfficientData, setRealVsEfficientData] = useState([]);
  const [dataForPurchasePerDm, setDataForPurchasePerDm] = useState({});
  const [draggedItem, setDraggedItem] = useState<number | null>(null);

  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      const result = arrayMoveImmutable(items.compra, oldIndex, newIndex);
      updateItems("compra", result);
      setDraggedItem(null);
    },
    [items, updateItems]
  );
  const onDragStart = useCallback((index) => {
    setDraggedItem(index);
  }, []);

  return (
    <Container
      reorderstate={isReordering}
      isvisible={isReordering || visibility.compraEficiente}
    >
      {" "}
      <Header>
        {isReordering ? (
          <>
            <TitleContainer>
              <SortableKnob>
                <Box>
                  <Icon as="drag" color="neutral-600" />
                </Box>
              </SortableKnob>
              <Title>Compra eficiente</Title>
            </TitleContainer>
            <Box>
              {" "}
              {visibility.compraEficiente ? (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("compraEficiente")}
                >
                  <Icon as="eye" type="outline" />
                </Button>
              ) : (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("compraEficiente")}
                >
                  <Icon as="eyeOff" />
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Title>Compra eficiente</Title>
        )}
      </Header>
      <Line></Line>
      <GridContainer
        onSortEnd={onSortEnd}
        onDragStart={onDragStart}
        allowDrag={!!isReordering}
      >
        <Shadow isvisible={isReordering} style={{ gridArea: items.compra[0] }}>
          <SortableItem key={items.compra[0]}>
            <Widget
              style={{ gridArea: items.compra[0] }}
              isvisible={
                isReordering || visibility.real_and_orders_purchase_per_dm
              }
            >
              <DrugstoreOrdersAndTotalAmount />
            </Widget>
          </SortableItem>
        </Shadow>

        <Shadow isvisible={isReordering} style={{ gridArea: items.compra[1] }}>
          <SortableItem key={items.compra[1]}>
            <Widget
              style={{ gridArea: items.compra[1] }}
              isvisible={
                isReordering || visibility.real_and_orders_purchase_per_pos
              }
            >
              <POSOrdersAndTotalAmount />
            </Widget>
          </SortableItem>
        </Shadow>
      </GridContainer>
    </Container>
  );
};
export default EfficientPurchasing;

const Widget = styled.div<{
  isvisible: boolean;
}>`
  /* margin: 10px 0px 0px 0px; */
  background: #fff;
  border-radius: 8px;
  padding: 20px 15px 10px 15px;
  box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1),
    0px 1px 2px 0px rgba(64, 67, 91, 0.06);
  flex-direction: column;
  display: ${(props) => (props.isvisible ? "flex" : "none")};
`;

const Shadow = styled.div<{
  isvisible: boolean;
}>`
  /* border: ${(props) =>
    props.isvisible
      ? "0.5px dashed var(--Semantic-Information-600, #1a66fc)"
      : "transparentize"}; */
  background: ${(props) =>
    props.isvisible
      ? "var(--Semantic-Information-50, #f2f7ff);"
      : "transparentize"};
  box-shadow: ${(props) =>
    props.isvisible
      ? "0px 1px 3px 0px rgba(64, 67, 91, 0.1)" +
        "," +
        "0px 1px 2px 0px rgba(64, 67, 91, 0.06)"
      : "transparentize"};
  border-radius: 8px;
`;
const TitleContainer = styled.div`
  width: 190px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DataMessage = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div<{
  isvisible: boolean;
  reorderstate: boolean;
}>`
  width: 100%;
  margin: 10px 0px 10px 0px;
  position: relative;
  padding: 0px 10px;
  background: ${(props) => (props.reorderstate ? "#C6D9FE" : "transparent")};
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  display: ${(props) => (props.isvisible ? "flex" : "none")};
`;
const GridContainer = styled(SortableList)`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 50px;
  grid-template-areas:
    "first first"
    "second second";

  margin-top: 20px;
`;
