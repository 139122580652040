import { SortableKnob } from "react-easy-sort";
import {
  ChartContainer,
  WidgetHeaderSubContainer,
} from "../PurchaseAnalysis/Analysis";
import {
  WidgetDescription,
  WidgetTitle,
  WidgetTitleContainer,
} from "../Resume/Resume";
import {
  analisisValues,
  formatDateToYYYYMMDD,
  message,
} from "../../../utils/formats";
import { DataMessage } from "./EfficientPurchasing";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { useDataProvider } from "../../../context/provider";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
} from "@extendeal/vendors-design-system";
const DrugstoreOrdersAndTotalAmount = () => {
  const { isReordering, visibility, toggleWidgetVisibility } =
    useReorderProvider();
  const { state, isLoading } = useDataProvider();

  const drugManufacturerNames: string[] = state.purchasePerDm?.map(
    (p) => p.drug_manufacturer_name
  );
  const totalPurchase = state.purchasePerDm?.map((p) => {
    return p.medicinal_total_orders + p.non_medicinal_total_orders;
  });

  const totalOrders = state.purchasePerDm?.map((p) => p.num_orders);
  return (
    <>
      <WidgetHeaderSubContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <WidgetTitleContainer>
          {isReordering && (
            <SortableKnob>
              <Box>
                <Icon as="drag" color="neutral-600" />
              </Box>
            </SortableKnob>
          )}
          <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
            <Icon as="storeFront" type="outline" color="neutral-800" />
          </Box>
          <WidgetTitle>
            {analisisValues.real_and_orders_purchase_per_dm.title}
          </WidgetTitle>
        </WidgetTitleContainer>
        {isReordering && (
          <Box>
            {" "}
            {visibility.real_and_orders_purchase_per_dm ? (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("real_and_orders_purchase_per_dm")
                }
              >
                <Icon as="eye" type="outline" />
              </Button>
            ) : (
              <Button
                backgroundColor={"neutral-white"}
                p={"1"}
                onClick={() =>
                  toggleWidgetVisibility("real_and_orders_purchase_per_dm")
                }
              >
                <Icon as="eyeOff" />
              </Button>
            )}
          </Box>
        )}
      </WidgetHeaderSubContainer>
      <WidgetDescription>
        {analisisValues.real_and_orders_purchase_per_dm.description}
      </WidgetDescription>
      {isLoading ? (
        <Stack alignItems={"center"} justifyContent="center">
          <Skeleton w={"100%"} h="400px" />
        </Stack>
      ) : (
        <ChartContainer>
          {state.purchasePerDm?.length ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: ".",
                  style: {
                    color: "white",
                  },
                },
                xAxis: {
                  categories: drugManufacturerNames,
                  crosshair: true,
                  accessibility: {
                    description: "",
                  },
                  gridLineWidth: 1,
                  gridLineColor: "#e0e0e0",
                },
                yAxis: [
                  {
                    title: {
                      text: "Compra efectuada",
                      style: {
                        color: "#333333",
                        fontSize: 14,
                        fontWeight: 600,
                      },
                    },

                    gridLineWidth: 1,
                    gridLineColor: "#e0e0e0",
                    gridLineDashStyle: "Dot",
                  },
                  {
                    title: {
                      text: "Órdenes de compra",
                      style: {
                        color: "#333333",
                        fontSize: 14,
                        fontWeight: 600,
                      },
                    },
                    allowDecimals: false,
                    opposite: true,
                    gridLineWidth: 1,
                    gridLineColor: "#e0e0e0",
                    gridLineDashStyle: "Dot",
                  },
                ],
                plotOptions: {
                  column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                  },
                },
                exporting: {
                  enabled: true,
                  filename: `${formatDateToYYYYMMDD(new Date())}_${
                    analisisValues.real_and_orders_purchase_per_dm.title
                  }`,
                  buttons: {
                    contextButton: {
                      menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "downloadXLS",
                      ],
                    },
                  },
                },
                series: [
                  {
                    name: "Compra efectuada",
                    data: totalPurchase,
                    yAxis: 0,
                    color: "#002DAD",
                  },
                  {
                    name: "Órdenes de compra",
                    data: totalOrders,
                    type: "line",
                    yAxis: 1,
                    color: "#0094FF",
                  },
                ],
                credits: {
                  enabled: false,
                },
              }}
            />
          ) : (
            <DataMessage>{message.no_data}</DataMessage>
          )}
        </ChartContainer>
      )}
    </>
  );
};
export default DrugstoreOrdersAndTotalAmount;
