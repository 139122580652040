// This file exports variables from each FF to centralize changes
export const EX3771 = process.env.NEXT_PUBLIC_EX3771 === 'true'; //EPIC RECOMMENDED PRODUCTS

export const DEV = process.env.NODE_ENV === 'development';

export const EX3261 = process.env.NEXT_PUBLIC_EX3261 === 'true';

export const PUSHER_LOGS = process.env.NEXT_PUBLIC_PUSHER_LOGS === 'true';

export const QR_AFIP_KEY = process.env.NEXT_PUBLIC_QR_AFIP_KEY;

export const EX4121 = process.env.NEXT_PUBLIC_EX4121 === 'true'; //FF FIX 4121

export const EX4073 = process.env.NEXT_PUBLIC_EX4073 === 'true';

export const EX3918 = process.env.NEXT_PUBLIC_EX3918 === 'true'; //FF motive errors

export const EX3986 = process.env.NEXT_PUBLIC_EX3986 === 'true'; //FF motive errors

export const EX4163 = process.env.NEXT_PUBLIC_EX4163 === 'true'; //FF de T&C por ubicación

export const EX4201 = process.env.NEXT_PUBLIC_EX4201 === 'true'; //FF changes on "droguería/s" to "distribuidora/s"

export const EX4204 = process.env.NEXT_PUBLIC_EX4204 === 'true'; //FF recommended products in home default

export const EX4115 = process.env.NEXT_PUBLIC_EX4115 === 'true'; //FF animations

export const EX3991 = process.env.NEXT_PUBLIC_EX3991 === 'true'; //FF changes on texts by country in production environment

export const EX4293 = process.env.NEXT_PUBLIC_EX4293 === 'true'; //FF fix without stock in ce table

export const EX3762 = process.env.NEXT_PUBLIC_EX3762 === 'true'; //FF Modal Multiple Discount

export const EX4207 = process.env.NEXT_PUBLIC_EX4207 === 'true'; //FF fix button change password

export const EX4380 = process.env.NEXT_PUBLIC_EX4380 === 'true'; //FF add format within dropzone

export const EX4317 = process.env.NEXT_PUBLIC_EX4317 === 'true'; //FF

export const EX4313 = process.env.NEXT_PUBLIC_EX4313 === 'true'; // retry - update - credentials  (EX4312 - 4313 - 4315)

export const EX3485 = process.env.NEXT_PUBLIC_EX3485 === 'true'; //FF when remove product in ce table

export const EX4367 = process.env.NEXT_PUBLIC_EX4367 === 'true'; //FF recommended products v2 ce table

export const EX4402 = process.env.NEXT_PUBLIC_EX4402 === 'true'; //FF recommended products v2 when add product by suggested

export const EX4327 = process.env.NEXT_PUBLIC_EX4327 === 'true'; //FF remove border-radius recommended products row in CE table

export const EX4453 = process.env.NEXT_PUBLIC_EX4453 === 'true'; //FF new chips pvp in ce table

export const EX4329 = process.env.NEXT_PUBLIC_EX4329 === 'true'; //FF fix run fill when add recommended and prev manual selection

export const EX4396 = process.env.NEXT_PUBLIC_EX4396 === 'true'; //FF change $ to € by country

export const EX4378 = process.env.NEXT_PUBLIC_EX4378 === 'true'; //FF fix click on buttons in sidebar and header meanwhile loading screen for efficient purchase is active

export const EX4514 = process.env.NEXT_PUBLIC_EX4514 === 'true'; //FF add alert warning reload products in table ce after config credentials with errors

export const EX4531 = process.env.NEXT_PUBLIC_EX4531 === 'true'; //FF add new validation to drugmanufacturers credentials on configuration by priority and percentage

export const EX4529 = process.env.NEXT_PUBLIC_EX4529 === 'true'; //FF add limit quantity in counterBox

export const EX3990 = process.env.NEXT_PUBLIC_EX3990 === 'true'; //FF refactor auto config credentials add validation when only one method

export const EX3992 = process.env.NEXT_PUBLIC_EX3992 === 'true'; //FF changes on texts by country in production environment

export const EX4503 = process.env.NEXT_PUBLIC_EX4503 === 'true'; //FF changes in row of CE

export const EX4085 = process.env.NEXT_PUBLIC_EX4085 === 'true'; //FF changes on texts by country about My Orders module

export const EX4562 = process.env.NEXT_PUBLIC_EX4562 === 'true'; //FF CdO scope reducido

export const EX4572 = process.env.NEXT_PUBLIC_EX4572 === 'true'; //FF CdO scope reducido fixed last column

export const EX4346 = process.env.NEXT_PUBLIC_EX4346 === 'true'; //FF ver más / ver menos

export const EX4467 = process.env.NEXT_PUBLIC_EX4467 === 'true'; //FF fix cart in other views

export const EX4600 = process.env.NEXT_PUBLIC_EX4600 === 'true'; //FF droguerías farmacias abc mx

export const HIDDENMODALRECOMMENDED = process.env.NEXT_PUBLIC_HIDDENMODALRECOMMENDED === 'true'; //FF hidden modal recommended

export const EX4568 = process.env.NEXT_PUBLIC_EX4568 === 'true'; //FF Rediseño CE v2

export const EXCEPTIONSHOWTABSSTAGING = process.env.NEXT_PUBLIC_EXCEPTIONSHOWTABSSTAGING === 'true'; //FF show tabs connection in staging

export const EX4744 = process.env.NEXT_PUBLIC_EX4744 === 'true'; //FF fix init comparison

export const EX4698 = process.env.NEXT_PUBLIC_EX4698 === 'true'; // FF detail products

export const EX4570 = process.env.NEXT_PUBLIC_EX4570 === 'true'; //FF context menu;

export const EX4780 = process.env.NEXT_PUBLIC_EX4780 === 'true'; //FF fix footer home

export const EX4569 = process.env.NEXT_PUBLIC_EX4569 === 'true'; //FF hidden sidebar

export const EX4292 = process.env.NEXT_PUBLIC_EX4292 === 'true'; //FF hidden back table ce

export const EX4693 = process.env.NEXT_PUBLIC_EX4693 === 'true'; //FF thumbnail open search

export const EX4666 = process.env.NEXT_PUBLIC_EX4666 === 'true'; //FF replace pagination component in orders

export const EX4135 = process.env.NEXT_PUBLIC_EX4135 === 'true'; //FF new texts and translation to ES-Neutro

export const EX4745 = process.env.NEXT_PUBLIC_EX4745 === 'true'; //FF fix styles in cells comparision

export const EX4825 = process.env.NEXT_PUBLIC_EX4825 === 'true'; // FF motive errors

export const EX4614 = process.env.NEXT_PUBLIC_EX4614 === 'true'; // FF add new file format to the dropzone

export const EX4883 = process.env.NEXT_PUBLIC_EX4883 === 'true'; // FF fix old footer size

export const EX4786 = process.env.NEXT_PUBLIC_EX4786 === 'true'; // FF Admin Search

export const EX3900 = process.env.NEXT_PUBLIC_EX3900 === 'true'; // FF Notification Center

export const EX4616 = process.env.NEXT_PUBLIC_EX4616 === 'true'; // FF translation texts

export const EX4882 = process.env.NEXT_PUBLIC_EX4882 === 'true'; // FF share credential

// export const EX4885 = process.env.NEXT_PUBLIC_EX4885 === 'true'; // FF fix show icons cell only checked

// export const EX4887 = process.env.NEXT_PUBLIC_EX4887 === 'true'; // FF add validation with service

// export const EX4863 = process.env.NEXT_PUBLIC_EX4863 === 'true'; // FF fix width agreement tag and icons

export const EX4902 = process.env.NEXT_PUBLIC_EX4902 === 'true'; // FF fix width chip cell

export const EX4904 = process.env.NEXT_PUBLIC_EX4904 === 'true'; // FF Redesing 2.5

export const EX4796 = process.env.NEXT_PUBLIC_EX4796 === 'true'; // FF fix heigth card recommended

export const EX4918 = process.env.NEXT_PUBLIC_EX4918 === 'true'; // FF fix zIndex and left in cell product

export const EX4923 = process.env.NEXT_PUBLIC_EX4923 === 'true'; // FF fix discount chip

export const EX4827 = process.env.NEXT_PUBLIC_EX4827 === 'true'; // FF add inputSelect to render deposit in Coopidrogas

export const EX4914 = process.env.NEXT_PUBLIC_EX4914 === 'true'; // FF text modal duplicated credentials

export const EX4980 = process.env.NEXT_PUBLIC_EX4980 === 'true'; // FF fix background radio button disabled in cdo column

export const FULLSCREEN = process.env.NEXT_PUBLIC_FULLSCREEN === 'true'; // FF fullscreen

export const EX4988 = process.env.NEXT_PUBLIC_EX4988 === 'true'; // tooltip min value

export const EX4893 = process.env.NEXT_PUBLIC_EX4893 === 'true'; // FF dispatch addresses by Cofaloza

export const EX4927 = process.env.NEXT_PUBLIC_EX4927 === 'true'; //FF new layout table ce v3

export const EX5057 = process.env.NEXT_PUBLIC_EX5057 === 'true'; // FF translate all to PT-BR

export const EX4668 = process.env.NEXT_PUBLIC_EX4668 === 'true'; // FF new modals

export const EX4770 = process.env.NEXT_PUBLIC_EX4770 === 'true'; // FF credential with one api credential id

export const LOCALIZATION = process.env.NEXT_PUBLIC_LOCALIZATION === 'true'; //  FF automation localization

export const EX5091 = process.env.NEXT_PUBLIC_EX5091 === 'true'; // FF detail card v2

export const EX5021 = process.env.NEXT_PUBLIC_EX5021 === 'true'; // FF add client identifier to

export const EX5138 = process.env.NEXT_PUBLIC_EX5138 === 'true'; // FF Localization PT-BR V2

export const EX4903 = process.env.NEXT_PUBLIC_EX4903 === 'true'; // FF New Limit Credential

export const EX4922 = process.env.NEXT_PUBLIC_EX4922 === 'true'; // FF new pdf v2 MX

export const EX5036 = process.env.NEXT_PUBLIC_EX5036 === 'true'; // FF new pdf v2 CO

export const EX5184 = process.env.NEXT_PUBLIC_EX5184 === 'true'; // FF fix label upload file commercial agreements

export const EX4926 = process.env.NEXT_PUBLIC_EX4926 === 'true'; // FF Delivery Time

export const EX5225 = process.env.NEXT_PUBLIC_EX5225 === 'true'; // FF fix translation US

export const EX5133 = process.env.NEXT_PUBLIC_EX5133 === 'true'; // FF fix success page sent label

export const EX5232 = process.env.NEXT_PUBLIC_EX5232 === 'true'; // FF fix translation US v2

export const EX5103 = process.env.NEXT_PUBLIC_EX5103 === 'true'; // FF fix checkbox in red color from CE when a product is unselected and without stock.

export const EX5461 = process.env.NEXT_PUBLIC_EX5461 === 'true'; // FF hot fix vertical scroll and padding right in footer

export const EX5250 = process.env.NEXT_PUBLIC_EX5250 === 'true'; // FF refactor error type

export const EX5411 = process.env.NEXT_PUBLIC_EX5411 === 'true'; // FF hot fix height table ce with alert

export const EX5673 = process.env.NEXT_PUBLIC_EX5673 === 'true'; //FF add error input by offline form

export const EX5674 = process.env.NEXT_PUBLIC_EX5674 === 'true'; // FF hot fix height table ce with alert

export const EX5747 = process.env.NEXT_PUBLIC_EX5747 === 'true'; // FF update terms and conditions to AR, MX and CO.

export const EX5788 = process.env.NEXT_PUBLIC_EX5788 === 'true'; // FF fix show agreement applies

export const EX5783 = process.env.NEXT_PUBLIC_EX5783 === 'true'; //FF fix header menu dropdown

export const EX5497 = process.env.NEXT_PUBLIC_EX5497 === 'true'; //FF fix header menu dropdown

export const EX5791 = process.env.NEXT_PUBLIC_EX5791 === 'true'; // FF fix push router when is US

export const EX5817 = process.env.NEXT_PUBLIC_EX5817 === 'true'; // cofaloza

export const EX5511 = process.env.NEXT_PUBLIC_EX5511 === 'true'; // FF fix text delete credential with autoconfig

export const EX5221 = process.env.NEXT_PUBLIC_EX5225 === 'true'; // FF Admin

export const EX5971 = process.env.NEXT_PUBLIC_EX5971 === 'true'; // FF fix time progress bar

export const EX5970 = process.env.NEXT_PUBLIC_EX5970 === 'true'; // FF background color with quantity zero

export const EX6002 = process.env.NEXT_PUBLIC_EX6002 === 'true'; // FF remove file extension validation

export const EX5942 = process.env.NEXT_PUBLIC_EX5942 === 'true'; // delete logic

export const EX5907 = process.env.NEXT_PUBLIC_EX5907 === 'true'; // new cell styles

export const EX6156 = process.env.NEXT_PUBLIC_EX6156 === 'true'; // #FF selection manual when update counterbox

export const EX6213 = process.env.NEXT_PUBLIC_EX6213 === 'true'; //#FF resize new dropzone when open cart and expanded

export const EX6209 = process.env.NEXT_PUBLIC_EX6209 === 'true'; //#FF selection manual when selectedAllColumn

export const EX6166 = process.env.NEXT_PUBLIC_EX6166 === 'true'; // FF fix show modal alert prevent step two in form connection all countries

export const EX6229 = process.env.NEXT_PUBLIC_EX6229 === 'true'; //FF fix dropzone colors

export const EX6167 = process.env.NEXT_PUBLIC_EX6167 === 'true'; // FF fix inputs extra in first step from form connection all countries

export const EX6230 = process.env.NEXT_PUBLIC_EX6230 === 'true'; // FF validate format

export const EX6022 = process.env.NEXT_PUBLIC_EX6022 === 'true'; //FF add error credit in orders

export const EX6158 = process.env.NEXT_PUBLIC_EX6158 === 'true'; // FF remove hint in config

export const EX6243 = process.env.NEXT_PUBLIC_EX6243 === 'true'; // FF fix UI rediseño blockerScreen home

export const IS_LOCAL_ENV = process.env.NEXT_PUBLIC_IS_LOCAL_ENV === 'true'; // is local dev

export const EX6157 = process.env.NEXT_PUBLIC_EX6157 === 'true'; // FF background color cell

export const EX5872 = process.env.NEXT_PUBLIC_EX5872 === 'true'; // FF status badge unknown

export const EX6381 = process.env.NEXT_PUBLIC_EX6381 === 'true'; // FF delete filter vendors

export const EX6138 = process.env.NEXT_PUBLIC_EX6138 === 'true'; // FF Add download template link in offdrugmanufacturer

export const EX5539 = process.env.NEXT_PUBLIC_EX5539 === 'true'; // FF ERP Gestor filtrar como Zetti

export const EX6405 = process.env.NEXT_PUBLIC_EX6405 === 'true'; // FF add gap between end of inputs and scrollbar

export const EX6409 = process.env.NEXT_PUBLIC_EX6409 === 'true'; // FF fix error message in form connection when total drugmanufacturers connected is equals to 2

export const FFRECOMMENDED = process.env.NEXT_PUBLIC_FFRECOMMENDED === 'true'; // FF hotfix recommended

export const FFSPLIT = process.env.NEXT_PUBLIC_FFSPLIT === 'true'; // FF fix split

export const EX6468 = process.env.NEXT_PUBLIC_EX6468 === 'true'; // FF hot fix set product groups when fetching initial

export const EX6413 = process.env.NEXT_PUBLIC_EX6413 === 'true'; // FF change view recovery password

export const EX6355 = process.env.NEXT_PUBLIC_EX6355 === 'true'; // FF change EP requeste by offline id

export const AUTOCOMPLETE = process.env.NEXT_PUBLIC_AUTOCOMPLETE === 'true'; // FF change type email

export const EX6493 = process.env.NEXT_PUBLIC_EX6493 === 'true'; // FF fix header number

export const EX6370 = process.env.NEXT_PUBLIC_EX6370 === 'true'; // FF change payload sent in client_identifier to api-credential endpoint by Calderón.

export const EX6551 = process.env.NEXT_PUBLIC_EX6551 === 'true'; // FF fix calculo estas ahorrando

export const EX6534 = process.env.NEXT_PUBLIC_EX6534 === 'true'; // FF add translation key

export const EX6410 = process.env.NEXT_PUBLIC_EX6410 === 'true'; // FF deshabilitar button realizar pedido cuando se esta fetcheando algo

export const EX6562 = process.env.NEXT_PUBLIC_EX6562 === 'true'; // FF add params in EP

export const EX6561 = process.env.NEXT_PUBLIC_EX6561 === 'true'; // FF cambio de validación para enviadas en detail de pedidos y errores

export const EX6676 = process.env.NEXT_PUBLIC_EX6676 === 'true'; // FF fix avatars distribution

export const EX6689 = process.env.NEXT_PUBLIC_EX6689 === 'true'; // FF fix tooltip and disabled counterbox when split max quantity stock

export const EX6427 = process.env.NEXT_PUBLIC_EX6427 === 'true'; // FF fix confirmation component

export const EX6674 = process.env.NEXT_PUBLIC_EX6674 === 'true'; // FF deshabilitar close icon cuando solicita vendor

export const EX6694 = process.env.NEXT_PUBLIC_EX6694 === 'true'; // FF fix avatars prioridades

export const EX6697 = process.env.NEXT_PUBLIC_EX6697 === 'true'; // FF cambio desde donde obtenemos el serviceId

export const EX6742 = process.env.NEXT_PUBLIC_EX6742 === 'true'; // FF fix cantidad en counterbox no se actualiza cuando la quantity es diferente

export const EX6753 = process.env.NEXT_PUBLIC_EX6753 === 'true'; // FF agregar force_suggested en selección manual

export const EX6723 = process.env.NEXT_PUBLIC_EX6723 === 'true'; // FF ajuste calculo todavia podes ahorra

export const EX6760 = process.env.NEXT_PUBLIC_EX6760 === 'true'; // FF fix editar prioridades cuando tiene error se muestra

export const EX6780 = process.env.NEXT_PUBLIC_EX6780 === 'true'; // FF fix avanzar en comparativa cuando tiene genericos

export const COUNTER_ZERO = process.env.NEXT_PUBLIC_COUNTER_ZERO === 'true'; // FF fix counter llevado a zero en generico padre

export const EX6785 = process.env.NEXT_PUBLIC_EX6785 === 'true'; // FF fix select all cuando vendor es lowest y no fue aceptada aun

export const EX6791 = process.env.NEXT_PUBLIC_EX6791 === 'true'; // FF fix mostrar la vendor en desplegable de prioridades en config

export const EX6809 = process.env.NEXT_PUBLIC_EX6809 === 'true'; // FF worker table footer

export const PERFOMANCE_01 = process.env.NEXT_PUBLIC_PERFOMANCE_01 === 'true'; // FF separate footer context

export const EX6695 = process.env.NEXT_PUBLIC_EX6695 === 'true'; // FF change node tolerance

export const EX6914 = process.env.NEXT_PUBLIC_EX6914 === 'true'; // FF delete implementation save in localstorage vendor requested

export const EX6928 = process.env.NEXT_PUBLIC_EX6928 === 'true'; // FF add new state accepted and rejected in my orders

export const PERFOMANCE_02 = process.env.NEXT_PUBLIC_PERFOMANCE_02 === 'true'; // FF format response and minimunpurchase and validation webworker

export const EX7062 = process.env.NEXT_PUBLIC_EX7062 === 'true'; // FF change node distribution

export const EX7066 = process.env.NEXT_PUBLIC_EX7066 === 'true'; // FF change select all logic by backend

export const EX6808 = process.env.NEXT_PUBLIC_EX6808 === 'true'; // FF deprecated POST recommended initial home

export const EX7098 = process.env.NEXT_PUBLIC_EX7098 === 'true'; // FF new logic delete product in table

export const EX7099 = process.env.NEXT_PUBLIC_EX7099 === 'true'; //FF fix alerts select ubication

export const EX7160 = process.env.NEXT_PUBLIC_EX7160 === 'true'; // FF add validation remove parent when generic add

export const EX7197 = process.env.NEXT_PUBLIC_EX7197 === 'true'; // FF add fix when remove parent generic and restore quantity previous

export const EX7182 = process.env.NEXT_PUBLIC_EX7182 === 'true'; // FF fix chip and modal without selection reset when fetchin select all column
