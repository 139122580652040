import { State } from "./provider";

const areAnalyticsEmpty = (analytics: State): boolean => {
  const { resume, reorder, ...restAnalytics } = analytics;

  const isSummaryEmpty = Object.values(resume).every(
    (value) => value.total === 0 && value.variation === 0
  );
  const isRestEmpty = Object.values(restAnalytics).every(
    (values) => !values.length
  );

  return isSummaryEmpty && isRestEmpty;
};

export { areAnalyticsEmpty };
