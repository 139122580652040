import { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { useDataProvider } from "../../../context/provider";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useReorderProvider } from "../../../context/ReorderContext/provider";
import { Tooltip } from "react-tooltip";
import { transparentize } from "polished";
import {
  Box,
  Button,
  Icon,
  Skeleton,
  Stack,
} from "@extendeal/vendors-design-system";
import { NumberUtils } from "../../../utils";
import SummaryWidgetSkeleton from "./organisms/SummaryWidgetSkeleton";

const resumeValues = {
  total_purchased: {
    title: "Monto total comprado",
    description: "Total de dinero gastado.",
    icon: (
      <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
        <Icon as="sales" type="outline" color="neutral-800" />
      </Box>
    ),
    content: (value?: number): string => {
      if (!value) return "0";
      return NumberUtils.formatWithSuffix(value);
    },
  },
  buyed_vs_efficient: {
    title: "Monto comprado Vs. eficiente",
    description: "Diferencia entre el monto total comprado y eficiente.",
    icon: (
      <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
        <Icon as="sales" type="outline" color="neutral-800" />
      </Box>
    ),
    content: (value?: number): string => {
      if (!value) return "0";
      return NumberUtils.formatWithSuffix(value);
    },
  },
  medicinal_total: {
    title: "Monto total medicinal",
    description: "Total de dinero gastado en medicinales.",
    icon: (
      <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
        <Icon as="sales" type="outline" color="neutral-800" />
      </Box>
    ),
    content: (value?: number): string => {
      if (!value) return "0";
      return NumberUtils.formatWithSuffix(value);
    },
  },
  non_medicinal_total: {
    title: "Monto total no medicinal",
    description: "Total de dinero gastado en no medicinales.",
    icon: (
      <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
        <Icon as="sales" type="outline" color="neutral-800" />
      </Box>
    ),
    content: (value?: number): string => {
      if (!value) return "0";
      return NumberUtils.formatWithSuffix(value);
    },
  },
  orders: {
    title: "Órdenes realizadas",
    description: "Total de órdenes de compra realizadas.",
    icon: (
      <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
        <Icon as="receipt" type="outline" color="neutral-800" />
      </Box>
    ),
    content: (value?: number): string => {
      if (!value) return "0";
      return NumberUtils.formatWithSuffix(value);
    },
  },
  prices_variation: {
    title: "Dispersión de precios",
    description:
      "Muestra qué tan diferentes son los precios de las compras. Hasta 10 es bajo (precios similares), 10 a 20 medio, +20 alto (precios muy diferentes).",
    icon: (
      <Box borderRadius={"md"} backgroundColor={"secondary-50"} p={"1"}>
        <Icon as="percent" type="outline" color="neutral-800" />
      </Box>
    ),
    content: (value: number): string => {
      return value + "%";
    },
  },
};
const Resume = () => {
  const { state, isLoading } = useDataProvider();

  const {
    items,
    updateItems,
    startReorder,
    isReordering,
    visibility,
    toggleWidgetVisibility,
  } = useReorderProvider();

  const resumeKeys = state.resume ? Object.keys(state?.resume) : null;

  useEffect(() => {
    if (!resumeKeys) return;
    startReorder("resumen", resumeKeys);
  }, []);

  const onSortEnd = useCallback(
    (oldIndex, newIndex) => {
      const result = arrayMoveImmutable(items.resumen, oldIndex, newIndex);
      updateItems("resumen", result);
    },
    [items, updateItems]
  );

  return (
    <Container
      backgroundColor={
        isReordering ? "semantic-information-100" : "secondary-25"
      }
      // reorderstate={isReordering}
      // isvisible={isReordering || visibility.resumen}
    >
      <Header>
        <TitleContainer>
          {isReordering && (
            <SortableKnob>
              <Box>
                <Icon as="drag" color="neutral-600" />
              </Box>
            </SortableKnob>
          )}
          <Title>Resumen </Title>
        </TitleContainer>
        <VisibilityContainer>
          {" "}
          {isReordering ? (
            <Box>
              {" "}
              {visibility.resumen ? (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("resumen")}
                >
                  <Icon as="eye" type="outline" />
                </Button>
              ) : (
                <Button
                  backgroundColor={"semantic-information-100"}
                  p={"1"}
                  onClick={() => toggleWidgetVisibility("resumen")}
                >
                  <Icon as="eyeOff" />
                </Button>
              )}
            </Box>
          ) : null}
        </VisibilityContainer>
      </Header>
      <Line></Line>
      <Sortable
        onSortEnd={onSortEnd}
        allowDrag={isReordering}
        style={{ width: "100%", display: "flex" }}
      >
        <WidgetContainer>
          {items.resumen?.map((key) => {
            return (
              <Box
                key={key}
                backgroundColor={
                  isReordering || visibility[key]
                    ? "semantic-information-50"
                    : "secondary-25"
                }
                borderRadius="md"
              >
                <SortableItem key={key}>
                  {isLoading ? (
                    <Widget isvisible={isReordering || visibility[key]}>
                      <SummaryWidgetSkeleton />
                    </Widget>
                  ) : (
                    <Widget isvisible={isReordering || visibility[key]}>
                      <Box>
                        <Stack
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <WidgetTitleContainer>
                            {isReordering ? (
                              <SortableKnob>
                                <Box>
                                  <Icon as="drag" color="neutral-600" />
                                </Box>
                              </SortableKnob>
                            ) : null}

                            {resumeValues[key]?.icon}
                            <WidgetTitle>
                              {resumeValues[key]?.title}
                            </WidgetTitle>
                          </WidgetTitleContainer>
                          {isReordering && (
                            <Box>
                              {" "}
                              {visibility[key] ? (
                                <Button
                                  backgroundColor={"neutral-white"}
                                  p={"1"}
                                  onClick={() => toggleWidgetVisibility(key)}
                                >
                                  <Icon as="eye" type="outline" />
                                </Button>
                              ) : (
                                <Button
                                  backgroundColor={"neutral-white"}
                                  p={"1"}
                                  onClick={() => toggleWidgetVisibility(key)}
                                >
                                  <Icon as="eyeOff" />
                                </Button>
                              )}
                            </Box>
                          )}
                        </Stack>
                        <WidgetDescription>
                          {resumeValues[key]?.description}
                        </WidgetDescription>
                      </Box>
                      <Stack
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <WidgetValue>
                          {resumeValues[key].content(state.resume[key]?.total)}
                        </WidgetValue>

                        {state.resume[key]?.variation >= 0 ? (
                          <>
                            <WidgetVariation
                              data-tooltip-id="variation-tooltip"
                              data-tooltip-content="Porcentaje de cambio respecto al período anterior"
                            >
                              <Icon
                                as="arrowDropUp"
                                color="semantic-success-600"
                              />

                              <VariationNumber
                                number={state.resume[key]?.variation}
                              >
                                {state.resume[key]?.variation}%
                              </VariationNumber>
                            </WidgetVariation>
                            <Tooltip
                              id="variation-tooltip"
                              style={{
                                background: transparentize(0.6, "#f0f5ff"),
                                color: "#9c9898",
                                fontSize: "12px",
                              }}
                            />
                          </>
                        ) : (
                          <WidgetVariation
                            data-tooltip-id="variation-tooltip"
                            data-tooltip-content="Porcentaje de cambio respecto al período anterior"
                          >
                            <Icon
                              as="arrowDropDown"
                              color="semantic-error-600"
                            />

                            <VariationNumber
                              number={state.resume[key]?.variation}
                            >
                              {state.resume[key]?.variation}%
                            </VariationNumber>
                          </WidgetVariation>
                        )}
                      </Stack>
                    </Widget>
                  )}
                </SortableItem>
              </Box>
            );
          })}
        </WidgetContainer>
      </Sortable>
    </Container>
  );
};
export default Resume;

const VariationNumber = styled.div<{ number: number }>`
  color: ${(props) => (props.number >= 0 ? "#07AD4B" : "#E22E2E")};
  font-size: 14px;
  font-weight: 600;
`;
const Sortable = styled(SortableList)`
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: space-between;
`;

export const WidgetDescription = styled.div`
  color: var(--Neutral-700, #646464);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 5px 0px 10px 0px;
`;
export const WidgetTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;

  color: #333;
`;
export const WidgetTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
`;
const WidgetValue = styled.div`
  color: var(--Neutral-800, #333);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  bottom: 0;
`;
const WidgetVariation = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;
// export const Icon = styled.img``;
const Widget = styled.div<{ isvisible: boolean }>`
  height: 150px;

  background: #fff;
  border-radius: 8px;
  padding: 20px 15px 10px 15px;
  box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1),
    0px 1px 2px 0px rgba(64, 67, 91, 0.06);
  display: ${(props) => (props.isvisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
`;
const WidgetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

export const Line = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #acaec4;
`;
export const VisibilityContainer = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333;
`;
export const Title = styled.div`
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 10px 0px;
`;

export const Container = styled(Box)`
  width: 100%;
  margin: 10px 0px 10px 0px;
  position: relative;
  padding: 0px 10px;

  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
