import { createContext, useContext, useMemo, useState } from 'react';

type ContextProps = {
    selectAllDrugManufacturerId: number | null;
    setSelectAllDrugManufacturerId: (v: number) => void;
};

const defaultValues: ContextProps = {
    selectAllDrugManufacturerId: null,
    setSelectAllDrugManufacturerId: (v: number) => {},
};

type Props = {
    children: React.ReactNode;
};

const SelectAllDrugManufacturerContext = createContext<ContextProps>(defaultValues);

export const SelectAllDrugManufacturerProvider = ({ children }: Props) => {
    const [selectAllDrugManufacturerId, setSelectAllDrugManufacturerId] = useState<number | null>(
        defaultValues.selectAllDrugManufacturerId,
    );

    const value: ContextProps = useMemo(
        () => ({
            selectAllDrugManufacturerId,
            setSelectAllDrugManufacturerId,
        }),
        [selectAllDrugManufacturerId, setSelectAllDrugManufacturerId],
    );

    return (
        <SelectAllDrugManufacturerContext.Provider value={value}>{children}</SelectAllDrugManufacturerContext.Provider>
    );
};

export const useSelectAllDrugManufacturerContext = () => useContext(SelectAllDrugManufacturerContext);
