import {
    createCartItem,
    deleteCartItemById,
    emptyCartByPointOfSaleId,
    updateCartItem,
    getCartInProgress,
} from 'api/cart';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { existsProductInCartById, getProductCartById } from 'utils/cart';
import { formatObjectCreate, formatObjectUpdate } from './utils';
import { NewUseSnackbar } from 'context/snackbar/snackbar-context';
import { CartProduct, NewCartProviderProps } from './new-cart-interface';
import { useCreateOrder } from 'context/createOrder/create-order-context';
import { EX4115, EX5942 } from 'config/flags';
import { useToast } from '@commons/EXComponentsLibrary';

const NewCartContext = createContext<Omit<NewCartProviderProps, 'children'>>(null);

export function NewCartProvider({ children, ...props }) {
    // NEW STATES REDESING
    const [productsList, setProductsList] = useState([]);
    const [visibility, setVisibility] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [animation, setAnimation] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [actionRemove, setActionRemove] = useState<any>({ id: null, state: false });
    const { showSnackbar, isDiscard, setShowSnackbar } = NewUseSnackbar();
    const { runCreateOrderByCart } = useCreateOrder();
    // STATES WHEN ADD PRODUCT AND ANIMATION
    const [animationInProduct, setAnimationInProduct] = React.useState<any>({ barcode: [], fade: null });
    const [counter, setCounter] = useState<number>(0);
    const [timeoutId, setTimeoutId] = useState<any | undefined>(undefined);
    const { showToaster, clearToaster } = useToast();
    const [ isFetching , setIsFetching ] = useState({})

    useEffect(() => {
        // DELETE PRODUCT BY ID
        if (actionRemove.state && !showSnackbar && !isDiscard) {
            handleDeleteProduct();
        }
    }, [showSnackbar]);

    // FUNCTION ACTIVE ANIMATION FADE IN ROW PRODUCT WHEN ADD OR DELETE EX4115
    const handleAnimationProduct = (barcode: number | string, fade: string | null = null) => {
        if (EX4115) {
            if (!!barcode) {
                setAnimationInProduct({ barcode: [...animationInProduct.barcode, barcode], fade: fade });
            } else {
                setAnimationInProduct({ barcode: [], fade: null });
            }
        }
    };

    // Aux function debounced when add products EX4115
    const debounce = (func, delay) => {
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            const timeout = setTimeout(() => {
                func(...args);
            }, delay);
            setTimeoutId(timeout);
            setCounter(0);
        };
    };
    // Aux Function to be executed after 2 seconds of inactivity EX4115
    const fetchCart = async () => {
        await getCart(false);
    };

    const handleDeleteProduct = () => {
        // DELETE PRODUCT BY ID
        deleteProductById(actionRemove.id);
    };

    const setterRemove = (id: number | null, state: boolean) => {
        setActionRemove({ id, state });
    };

    const handleResetVisibility = (): void => {
        setVisibility(false);
        setIsExpanded(false);
        setOpenModalDelete(false);
        setAnimation(false);
        setterRemove(null, false);
    };

    const setterFetching = (v): void => {
        setFetching(v);
    };

    const handleSetterVisibility = useCallback(() => {
        setterFetching(true);
        setAnimation(true);
        setTimeout(() => {
            setVisibility(false);
            setIsExpanded(false);
            setAnimation(false);
        }, 200);
    }, [visibility]);

    const toggle = useCallback(
        (posId: number) => {
            setShowSnackbar(false);
            setterFetching(true);
            setAnimation(true);
            setTimeout(() => {
                if (!visibility) {
                    getCart(posId);
                }
                setVisibility(!visibility);
                setIsExpanded(false);
                setAnimation(false);
            }, 200);
        },
        [visibility],
    );

    const expand = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]);

    // CARGAR CARRITO
    const loadProductsInCart = async (products: any[]) => {
        if (EX4115) {
            setProductsList(products);
            setTimeout(() => {
                // DESACTIVE ANIMATION FADE WHEN ADD IN CART
                handleAnimationProduct(null);
            }, 200);
        } else {
            setProductsList(products);
        }
    };

    // GET PDV SELECTED
    const getPdvLocalStorage = (): any => {
        const pdvOfLocalStorage: any = JSON.parse(localStorage.getItem('pdvSelected'));
        return pdvOfLocalStorage;
    };

    // AGREGAR UN PRODUCTO
    const addProduct = async (product: any) => {
        if (EX4115) setCounter((prev) => prev + 1);
        const newProduct = formatObjectCreate(product);
        setIsFetching((prevState) => ({ ...prevState, [newProduct?.barcode]: true }));
        // Active animation fade when add in cart
        if (EX4115) handleAnimationProduct(newProduct?.barcode, 'add');
        // CREATE PRODUCT
        const productInCart = await createCartItem(getPdvLocalStorage()?.id, newProduct);
        const { cart_id } = productInCart.data.data;
        // IT'S ANOTHER CART
        if (!!productsList.length && productsList[0].cart_id !== cart_id) {
            // SHOW SNACKBAR AND RESET STATE WITH ONE PRODUCT
            // setOngoingComparisonSnackBar(true);
            loadProductsInCart([productInCart.data.data]);
            // Desactivate animation fade when add in cart
            if (EX4115) handleAnimationProduct(null);
        } else {
            if (EX4115) {
                // Create a debounced version of the function
                const debouncedFunction = debounce(fetchCart, 500);
                // Execute debounced cart
                if (counter === 0) {
                    debouncedFunction();
                }
            } else {
                return await getCart(false);
            }
        }
    };

    // GET CART IN PROGRESS
    const getCart = async (posId = null, startSnackbar = false) => {
        if (!fetching && startSnackbar) setterFetching(true);
        // if(pointOfSale) {
        const currentCart: any = await getCartInProgress(posId ? posId : getPdvLocalStorage()?.id);
        if (currentCart?.items && !!currentCart.items.length) {
            // LOAD CART WITH ALL PRODUCTS
            loadProductsInCart(currentCart.items);
        } else {
            loadProductsInCart([]);
        }
        // }
        setterFetching(false);
    };

    // VACIAR CARRITO
    const emptyCart = async (pointOfSaleId: number) => {
        await emptyCartByPointOfSaleId(pointOfSaleId);
        // setEmptyCartSnackbar(true);
        loadProductsInCart([]);
    };

    // ELIMINAR UN PRODUCTO DE LA DB POR ID
    const deleteProductById = async (id: number) => {
        const response = await deleteCartItemById(getPdvLocalStorage()?.id, id);
        // DESACTIVE ANIMATION FADE WHEN ADD IN CART
        if (EX4115) handleAnimationProduct(null);
        const { data } = response.data;
        if (data?.error && data.error === 'CART_IN_PROGRESS_NOT_FOUND') {
            // SHOW SNACKBAR AND RESET STATE
            // setOngoingComparisonSnackBar(true);
            loadProductsInCart([]);
        }
        return response;
    };

    // DESHACER PRODUCTO REMOVIDO DE CARRITO
    const undoItem = async (product: CartProduct, prodIndex: number) => {
        if (EX4115) handleAnimationProduct(animationInProduct?.barcode, 'add');
        clearToaster();
        const res = await createCartItem(getPdvLocalStorage()?.id, product);
        if (res.data.data) {
            setProductsList((prevProducts) => {
                const copyProducts = [...prevProducts];
                const updatedProduct = {
                    ...product,
                    id: res.data.data.id,
                };
                copyProducts.splice(prodIndex, 0, updatedProduct);
                return copyProducts;
            });
        }
    };

    const removeProductInState = async (product) => {
        !EX5942 && handleAnimationProduct(product.barcode, 'remove');

        const prodIndex = productsList.findIndex((prod) => prod.id === product.id);
        const res = await deleteProductById(product.id);
        if (res.status === 204) {
            await showToaster({
                message: { description: t('shoppingCart.shoppingCart_Thirteen') },
                type: 'success',
                actionButton: {
                    label: 'Deshacer',
                    action: () => undoItem(product, prodIndex),
                },
            });
            const newList = productsList.filter((item) => item.id !== product.id);
            setProductsList([...newList]);
        }
    };

    // OBTENER UN PRODUCTO POR ID
    const getProductByBarcode = (barcode: number) => {
        const product = getProductCartById(productsList, barcode);
        return product;
    };

    // VALIDAR SI EXISTE UN PRODUCTO POR ID
    const existsProductInCart = (barcode: number) => {
        const existProductInCart = existsProductInCartById(productsList, barcode);
        return existProductInCart;
    };

    // ACTUALIZAR CANTIDAD DE UN PRODUCTO POR ID
    const updateProduct = async (product: any, quantity: number) => {
        const newProduct = formatObjectUpdate(product, quantity);
        // ANIMATION EX4115
        if (EX4115) {
            if (quantity === 0) {
                setTimeout(() => {
                    handleAnimationProduct(product.barcode, 'remove');
                }, 900);
            }
            setTimeout(() => {
                handleAnimationProduct(null);
            });
        }
        // UPDATE PRODUCT QUANTITY
        const updateProductInCart: any = await updateCartItem(getPdvLocalStorage()?.id, product.id, newProduct);
        const { data } = updateProductInCart.data;

        if (data?.error && data.error === 'CART_IN_PROGRESS_NOT_FOUND') {
            // SHOW SNACKBAR BECAUSE ERROR AND RESTART STATE
            // setOngoingComparisonSnackBar(true);
            loadProductsInCart([]);
        } else {
            // LOAD STATE WITH PRODUCTS
            await getCart(false);
        }
    };

    // CANTIDAD DE PRODUCTOS EN CARRITO
    const quantityCart = (): number => {
        return productsList.length;
    };

    //ACCIÓN DE INICIAR COMPARATIVA, FETCH AL CREATE
    const initComparation = () => {
        runCreateOrderByCart(productsList[0].cart_id);
    };

    return (
        <NewCartContext.Provider
            value={{
                productsList,
                emptyCart,
                getProductByBarcode,
                existsProductInCart,
                addProduct,
                updateProduct,
                loadProductsInCart,
                deleteProductById,
                quantityCart,
                // NEW STATES
                undoItem,
                removeProductInState,
                getCart,
                visibility,
                toggle,
                isExpanded,
                expand,
                openModalDelete,
                setOpenModalDelete,
                animation,
                fetching,
                setterFetching,
                handleResetVisibility,
                initComparation,
                handleSetterVisibility,
                animationInProduct,
                setIsFetching,
                isFetching,
                ...props,
            }}
        >
            {children}
        </NewCartContext.Provider>
    );
}

export function NewUseCart() {
    const context = useContext(NewCartContext);

    if (context === undefined) {
        throw new Error('useCartContext must be used within a ConfirmationProvider');
    }

    return context;
}
