import useSWR from 'swr';

export function useOrderDeliveryStatus(orderId, path = 'deliveries') {
    const { data: order, mutate: mutateOrder, isValidating } = useSWR(`/api/orders/${orderId}/${path}`, {
        revalidateOnFocus: false, // No revalidar al recuperar el foco
        shouldRetryOnError: false, // No reintentar en caso de error
    });
    return { order, mutateOrder, isValidating };
}

export function useOrderProcess(orderId) {
    const { data: process, mutate: mutateOrderProcess } = useSWR(`/api/orders/${orderId}/process`);
    return { process, mutateOrderProcess };
}

export function useOrderCheckHaveShortages(orderId) {
    const { data, isValidating } = useSWR(`/api/orders/${orderId}/order-products?filters[availability]=unavailable`, {
        revalidateOnFocus: false, // No revalidar al recuperar el foco
        shouldRetryOnError: false, // No reintentar en caso de error
    });
    return { data, isValidating };
}