import { ProductInterface } from 'common/types';
import { EX3485, EX3771, EX7197 } from 'config/flags';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useUser } from 'utils/hooks';
import { useUnitsContext } from './units-context';

type ContextProps = {
    products: ProductInterface[];
    setProducts: (products: ProductInterface[]) => void;
    productGroups: ProductInterface[];
    setProductGroups: (products: ProductInterface[]) => void;
    setProductInfo: (product: ProductInterface) => void;
};

const defaultValues: ContextProps = {
    products: [],
    setProducts: (products: ProductInterface[]) => {},
    productGroups: [],
    setProductGroups: (products: ProductInterface[]) => {},
    setProductInfo: (product: ProductInterface) => {},
};

type Props = {
    children: React.ReactNode;
};

const ProductsContext = createContext<ContextProps>(defaultValues);

export const ProductsProvider = ({ children }: Props) => {
    const [products, setProducts] = useState<ProductInterface[]>(defaultValues.products);
    const [productGroups, setProductGroups] = useState<ProductInterface[]>(defaultValues.productGroups);
    const { setUnits } = useUnitsContext();
    const { user } = useUser({});
    const EX5937 = user?.EX5937; // ff split purchase
    const EX5266 = user?.EX5266; //ff hot fix calculate footer
    const EX5267 = user?.EX5267; //ff hot fix calculate footer when select all column
    const productsRef: any = useRef(products);
    const productGroupsRef: any = useRef(products);

    useEffect(() => {
        productsRef.current = products;
    },[products]);

    useEffect(() => {
        productGroupsRef.current = productGroups;
    },[productGroups]);

    const updateProduct = useCallback(
        (item: ProductInterface) => {
            const currentProducts = productsRef.current;
            const currentProductGroups = productGroupsRef.current;

            const idx = currentProducts?.findIndex(({ barcode }) => item?.barcode === barcode);
            const idxByProductsGroups = EX3485 && currentProductGroups?.findIndex(({ barcode }) => item?.barcode === barcode);
            const findGenerics = currentProductGroups?.filter((object) => object?.generics?.length > 0);
            const isGeneric = findGenerics
                ?.map((producto) => {
                    return {
                        ...producto,
                        generics: producto?.generics?.filter((generic) => generic.barcode === item?.barcode),
                    };
                })
                ?.filter((producto) => producto.generics.length > 0);
            const idxByProductsGroupsGeneric = currentProductGroups?.findIndex(
                ({ barcode }) => isGeneric[0]?.barcode === barcode,
            );

            // validation with FF ON and length for prevention
            if (EX3485 && currentProductGroups.length) {
                // It is necessary to update the product Groups when the
                // quantity is updated because otherwise it will be left with old data
                const newProductGroups: any = currentProductGroups;
                if (isGeneric?.length) {
                    const newQuantity = EX7197
                        ? item?.quantity === 0 && isGeneric?.generics?.some((generic) => generic.quantity > 0)
                            ? item.quantity
                            : newProductGroups[idxByProductsGroupsGeneric].quantity
                        : item?.quantity;

                    newProductGroups[idxByProductsGroupsGeneric].quantity = newQuantity;
                    
                    // newProductGroups[idxByProductsGroupsGeneric].products = item?.products;
                
                    setProductGroups(newProductGroups);
                } else {
                    newProductGroups[idxByProductsGroups].quantity = item?.quantity;

                    if (EX5266) {
                        newProductGroups[idxByProductsGroups].products = item?.products;
                    }
                }

                setProductGroups(newProductGroups);
            }

            const newProductArray: ProductInterface[] = EX5267 ? currentProducts : [...currentProducts];
            newProductArray[idx] = item;
            if (EX3771) {
                setProducts((oldState) => {
                    const combinedProducts: any = [...oldState, ...newProductArray];

                    // Usar un objeto para realizar un seguimiento de los productos únicos por order_product_id
                    const uniqueProductsMap: any = combinedProducts.reduce((map, product) => {
                        map[product.order_product_id] = product;
                        return map;
                    }, {});

                    // Convertir el objeto de productos únicos de nuevo a un array
                    const uniqueProductsArray: any = Object.values(uniqueProductsMap);

                    // Actualizar el estado con el array de productos únicos
                    return uniqueProductsArray;
                });
            } else {
                setProducts([...newProductArray]);
            }
        },
        [products, productGroups],
    );

    const newSetterUnitsByDrugManufacturers = useCallback(() => {
        const currentProducts = productsRef.current;
        const selectedUnits = [];
        currentProducts.forEach(({ drugManufacturerIdSelected, barcode, products, quantity }) => {
            if (Array.isArray(drugManufacturerIdSelected)) {
                drugManufacturerIdSelected?.forEach((id) => {
                    let quantitySelected = 0;
                    if (products[id]?.quantity_suggested) {
                        quantitySelected = products[id].quantity_suggested;
                    } else {
                        quantitySelected = quantity;
                    }
                    selectedUnits.push({
                        products: [{ barcode: barcode, quantity: quantitySelected }],
                        drugManufacturerId: id,
                    });
                });
            } else {
                selectedUnits.push({
                    products: [{ barcode: barcode, quantity: quantity }],
                    drugManufacturerId: drugManufacturerIdSelected,
                });
            }
        });

        setUnits(selectedUnits);
    }, [products]);

    const setProductInfo = (item: ProductInterface) => {
        const currentProducts = productsRef.current;
        const productExists = currentProducts?.some(({ barcode }) => barcode === item?.barcode);
        if (EX3771) {
            if (productExists) {
                updateProduct(item);
                if (EX5937) {
                    newSetterUnitsByDrugManufacturers();
                }
            } else {
                if (EX5937) {
                    newSetterUnitsByDrugManufacturers();
                }
                setProducts((prev) => [...prev, item]);
            }
        } else {
            if (productExists) {
                updateProduct(item);
            } else {
                setProducts((prev) => [...prev, item]);
            }
        }
    };

    const value: ContextProps = useMemo(
        () => ({
            products,
            setProducts,
            productGroups,
            setProductGroups,
            setProductInfo,
        }),
        [products, setProducts, productGroups, setProductGroups, setProductInfo],
    );

    return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};

export const useProductsContext = () => useContext(ProductsContext);
