import { useCallback } from "react";
import styled from "@emotion/styled";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useReorderProvider } from "../../context/ReorderContext/provider";
import { Box, Icon } from "@extendeal/vendors-design-system";
import { useDataProvider } from "../../context/provider";
import NoAnalyticsFound from "../organisms/NoAnalyticsFound";

export default function Main() {
  const { items, updateItems, isReordering } = useReorderProvider();
  const { isEmpty } = useDataProvider();
  const onSortEnd = useCallback(
    (oldIndex: number, newIndex: number) => {
      const result = arrayMoveImmutable(
        items.mainComponents,
        oldIndex,
        newIndex
      );
      updateItems("mainComponents", result);
    },
    [items, updateItems]
  );

  return (
    <>
      {isEmpty ? (
        <NoAnalyticsFound />
      ) : (
        <Box w={"100%"} px={"20"}>
          <SortableList onSortEnd={onSortEnd} allowDrag={isReordering}>
            {items?.mainComponents.map((item, index) => (
              <SortableItem key={index}>
                <Box>{item}</Box>
              </SortableItem>
            ))}
          </SortableList>
        </Box>
      )}
    </>
  );
}
