import { Skeleton, Stack } from "@extendeal/vendors-design-system";

const SummaryWidgetSkeleton = () => {
  return (
    <>
      <Stack flexDirection={"column"} gap="6">
        <Skeleton w={"80%"} h={"20px"} borderRadius="md" />
        <Skeleton w={"90%"} h={"14px"} borderRadius="md" />
      </Stack>
      <Stack justifyContent={"space-between"}>
        <Skeleton w={"20%"} h="20px" borderRadius={"md"} />
        <Skeleton w={"20%"} h="20px" borderRadius={"md"} />
      </Stack>
    </>
  );
};

export default SummaryWidgetSkeleton;
