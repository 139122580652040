import App from "./App";
import { DataProvider } from "./context/provider";
import { Preflight, ThemeProvider } from "@extendeal/vendors-design-system";

export default function Root() {
  return (
    <ThemeProvider>
      <Preflight />
      <DataProvider>
        <App />
      </DataProvider>
    </ThemeProvider>
  );
}
